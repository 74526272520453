.App {
}
* {
  font-family: "Raleway", sans-serif;
  box-sizing: border-box;
  user-select: none;
}

h2 {
  font-family: "Playfair Display", serif;
  font-size: 42px;
  font-style: normal;
  font-weight: 400;
}

button {
  background-color: #92e3a9;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding: 16px 32px;
  cursor: pointer;
}
.back {
  background: none;
  cursor: pointer;
  color: green;
  margin-top: 20px;
  text-align: left;
  width: fit-content;
}
.results {
  display: flex;
  flex-wrap: wrap;
  max-width: 1300px;
  margin: 0 auto;
}
.resultItem {
  width: 23%;
  margin: 10px;
}

.MuiFormGroup-root.css-dmmspl-MuiFormGroup-root {
  margin-bottom: 70px;
}
